<template>
  <div>
    <!--Header-->
    <div class="header bg-success pt-5 pb-7">
      <b-container>
        <div class="header-body">
          <b-row align-v="center" >
            <b-col lg="6">
              <div class="pr-5">
                <h1 class="display-2 text-white font-weight-bold mb-0">Solte a voz e deixe a diversão fluir!</h1>
                <h2 class="display-4 text-white font-weight-light">Transforme qualquer evento em uma festa inesquecível! Divirta-se com as músicas que marcaram gerações</h2>
                <p class="text-white mt-4">Seja você um amante do karaokê ou que está procurando uma maneira de animar suas festas, você veio ao lugar certo! Aqui oferecemos a maior variedade de equipamentos e músicas para garantir que todos possam curtir momentos cheios de música e alegria.</p>
                <div class="mt-5">
                  <a href="#solution" class="btn btn-neutral my-2">Conhecer mais</a>
                  <router-link to="/admin/produtos" class="btn btn-default my-2">Adquirir agora</router-link>
                </div>
              </div>
            </b-col>
            <b-col lg="6">
              <b-row class="pt-5">
                <b-col md="6">
                  <b-card no-body>
                    <b-card-body>
                      <div class="icon icon-shape bg-gradient-red text-white rounded-circle shadow mb-4">
                        <i class="ni ni-active-40"></i>
                      </div>
                      <h5 class="h3">+20 mil músicas</h5>
                      <p>As de sucesso no momento ou aquelas que vão juntar todo mundo em uma só voz.</p>
                    </b-card-body>
                  </b-card>
                  <b-card no-body>
                    <b-card-body >
                      <div class="icon icon-shape bg-gradient-info text-white rounded-circle shadow mb-4">
                        <i class="ni ni-active-40"></i>
                      </div>
                      <h5 class="h3">Em todos os momentos</h5>
                      <p>Divirtido, prático e agrádavel! Torne qualquer evento um show!</p>
                    </b-card-body>
                  </b-card>
                </b-col>
                <b-col md="6" class="pt-lg-5 pt-4">
                  <b-card no-body class="mb-4">
                    <b-card-body >
                      <div class="icon icon-shape bg-gradient-success text-white rounded-circle shadow mb-4">
                        <i class="ni ni-active-40"></i>
                      </div>
                      <h5 class="h3">Quando você precisar</h5>
                      <p>Você pode contar com um atendimento rápido e atencioso que vão te ajudar</p>
                    </b-card-body>
                  </b-card>
                  <b-card no-body class="mb-4">
                    <b-card-body >
                      <div class="icon icon-shape bg-gradient-warning text-white rounded-circle shadow mb-4">
                        <i class="ni ni-active-40"></i>
                      </div>
                      <h5 class="h3">Personalize seu evento</h5>
                      <p>Seja um evento corporativo, casamento ou festa temática você poderá definir gênero e tornar seu evento um espetáculo.</p>
                    </b-card-body>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <section class="py-6 pb-9 bg-default">
      <b-row class="justify-content-center text-center">
        <b-col md="6" id="solution">
          <h2 class="display-3 text-white">Uma solução completa</h2>
          <p class="lead text-white">
            Faça do seu próximo evento um momento inesquecível com o a alta qualidade que você merece. Entre em contato conosco hoje mesmo e deixe a festa começar!
          </p>
          <router-link to="/admkn/produtos" class="btn btn-success my-2">Adquirir agora</router-link>
        </b-col>
      </b-row>
    </section>
    <section class="section section-lg pt-lg-0 mt--7">
      <b-container>
        <b-row class="justify-content-center">
          <b-col lg="12" >
            <b-row >
              <b-col lg="4">
                <b-card no-body class="card-lift--hover shadow border-0">
                  <b-card-body class="py-5">
                    <div class="icon icon-shape bg-gradient-primary text-white rounded-circle mb-4">
                      <i class="ni ni-circle-08"></i>
                    </div>
                    <h4 class="h3 text-primary text-uppercase">Eventos corporativo</h4>
                    <p class="description mt-3">Tenha elegância e descontração.</p>
                    <div>
                      <b-badge pill variant="primary">Anos 90</b-badge>
                      <b-badge pill variant="primary">Internacional</b-badge>
                      <b-badge pill variant="primary">Clássicos</b-badge>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col lg="4">
                <b-card no-body class="card-lift--hover shadow border-0">
                  <b-card-body  class="py-5">
                    <div class="icon icon-shape bg-gradient-success text-white rounded-circle mb-4">
                      <i class="ni ni-satisfied"></i>
                    </div>
                    <h4 class="h3 text-success text-uppercase">Festas</h4>
                    <p class="description mt-3">Seja um casamento, aniversário ou qualque comemoração.</p>
                    <div>
                      <b-badge pill variant="success">Sertanejo</b-badge>
                      <b-badge pill variant="success">Funk</b-badge>
                      <b-badge pill variant="success">Anos 80</b-badge>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col lg="4">
                <b-card no-body class="card-lift--hover shadow border-0">
                  <b-card-body class="py-5">
                    <div class="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4">
                      <i class="ni ni-planet"></i>
                    </div>
                    <h4 class="h3 text-warning text-uppercase">Evento tematicos</h4>
                    <p class="description mt-3">Aquele momento de nostalgia e diversão que unirá todos.</p>
                    <div>
                      <b-badge pill variant="warning">Anos 60</b-badge>
                      <b-badge pill variant="warning">Anos 70</b-badge>
                      <b-badge pill variant="warning">Anos 80</b-badge>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section class="py-6 bg-white">
      <b-container>
        <b-row align-v="center" class="row-grid">
          <b-col md="6" class="order-md-2">
            <b-img src="img/theme/lp-site.png" fluid />
          </b-col>
          <b-col md="6" class="order-md-1">
            <div class="pr-md-5">
              <h1>Recursos fantástivos</h1>
              <p>This dashboard comes with super cool features that are meant to help in the process. Handcrafted components, page examples and functional widgets are just a few things you will see and love at first sight.</p>
              <ul class="list-unstyled mt-5">
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-badge pill variant="success" class="badge-circle mr-3">
                        <i class="ni ni-settings-gear-65"></i>
                      </b-badge>
                    </div>
                    <div>
                      <h4 class="mb-0">Geração de música instantânea</h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-badge pill variant="success" class="badge-circle mr-3">
                        <i class="ni ni-basket"></i>
                      </b-badge>
                    </div>
                    <div>
                      <h4 class="mb-0">Compra otimizada</h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <b-badge pill variant="success" class="badge-circle mr-3">
                        <i class="ni ni-satisfied"></i>
                      </b-badge>
                    </div>
                    <div>
                      <h4 class="mb-0">Suporte premium</h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section class="py-7 bg-white">
      <b-container>
        <b-row class="row-grid justify-content-center">
          <b-col lg="8" class="text-center">
            <h2 class="display-3">O que você achou desta <span class="text-success">forma de diversão?</span></h2>
            <div class="btn-wrapper">
              <a v-if="false" href="wa.me/+55" class="btn btn-success btn-icon mb-3 mb-sm-0">
                <span class="btn-inner--icon"><i class="ni ni-single-02"></i></span>
                <span class="btn-inner--text">Entre em contato conosco</span>
              </a>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <a v-if="false" href="wa.me/+55" class="whatsapp"></a>

  </div>
</template>
<script>
  import { Tooltip } from 'element-ui'
  export default {
    name: 'home-page',
    components: {
      [Tooltip.name]: Tooltip
    }
  };
</script>
<style>
.whatsapp{
  display: block;
  width: 70px;
  height: 70px;
  background: #ffffff;
  border-radius: 50%;
  position: fixed;
  right: 20px;
  bottom: 25px;
  background-image: url(/img/whatsapp.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50px 50px;
}
</style>